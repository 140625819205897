import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';

export const PrivacyPolicy: React.FC = () => {

    return (
        <div className="bv-privacy-policy-page page">
            <div className="breadcrumb-area">
                <div className="container h-100">
                    <div className="row h-100 align-items-center justify-content-center">
                        <div className="col-lg-5">
                            <div className="breadcrumb-title text-center">
                                <h2>Privacy Policy</h2>
                                <ul className="breadcrumb-list">
                                    <li>Home</li>
                                    <li> <FontAwesomeIcon icon={icon({ name: 'angle-double-right', style: 'solid' })} /> </li>
                                    <li>Privacy Policy</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="blog-details-area section-padding-100-50 bg-gray-cu">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="blog-details-content mb-50">
                                <h1 className="uk-margin-small-bottom">Privacy Policy</h1>
                                <p className="uk-text-lead uk-text-muted uk-margin-remove-top">1. When registering with tradexmining.live, the Client shall provide certain identifying details including, inter alia, information aimed at preventing Money Laundering.</p> <br />
                                <p className="uk-text-lead uk-text-muted uk-margin-remove-top">1.1 The company collects and stores the following client data: email, encrypted password, client's name and address.</p> <br />
                                <p className="uk-text-lead uk-text-muted uk-margin-remove-top">2. Client undertakes to provide true, accurate and updated information about his identity and is obliged not to impersonate another person or legal entity. Any change in Client's identifying details must be notified to the Company immediately and in any case not later than the 30th day from the change in such details.</p> <br />
                                <p className="uk-text-lead uk-text-muted uk-margin-remove-top">2.1 Client's details which were provided and/or will be provided by the Client during his/her activity with tradexmining.live may be used by the Company for sending Company's advertising content to the Client, unless the Client removes the mark approving the Company to do so. Such removal can be done when (i) opening an account or (ii) when receiving such advertising content or (iii) by logging in and going to My Account &gt; Personal Details. The Client may also send to the Company, at any time, an e-mail to support@tradexmining.live asking the Company to stop sending such advertising content. The aforesaid mark removal and /or email receipt by the Company will oblige the Company to cease sending advertisement content to the Client within seven business days.</p> <br />
                                <p className="uk-text-lead uk-text-muted uk-margin-remove-top">2.2 Client details which were provided and/or will be provided by the Client during his/her activity on the site, may be disclosed by the Company to official authorities. The company will make such disclosure only if required to do so by applicable law, regulation or court order and at the minimum required extent.</p> <br />
                                <p className="uk-text-lead uk-text-muted uk-margin-remove-top">2.3 Non-confidential information about the Client can be used by the Company in any advertising materials.</p> <br />
                                <p className="uk-text-lead uk-text-muted uk-margin-remove-top">3. As a precondition for performing Transactions on the Site, Client may be asked to provide certain identifying documents and any other documents required by the Company. If such documents are not provided, the Company can, at its sole discretion, freeze the Client's Account for any period of time as well as to permanently close the Account. Without prejudice to the above, the Company may, at its sole discretion, refuse to open an Account for any person or entity and for any reason, or no reason.</p> <br />
                                <p className="uk-text-lead uk-text-muted uk-margin-remove-top">4. In case a person registers with tradexmining.live on behalf of a corporation or other business entity Client, such registration shall be considered as a representation by such person, that such person is authorized to bind the corporation or other business entity Client.</p> <br />
                                <p className="uk-text-lead uk-text-muted uk-margin-remove-top">5. The Company shall not divulge any private information of its Clients and former Clients unless the Client approved in writing such disclosure or unless such disclosure is required under applicable law or is required in order to verify Client's identity. The Clients' information is passed only to employees of the Company dealing with Client's Accounts. All such information shall be stored on electronic and physical storage media according to applicable law.</p> <br />
                                <p className="uk-text-lead uk-text-muted uk-margin-remove-top">6. Client confirms and agrees that all or part of the information concerning Client's Account and Transactions will be stored by the Company and may be used by the Company in case of dispute tradeween Client and the Company.</p> <br />
                                <p className="uk-text-lead uk-text-muted uk-margin-remove-top">7. Po sopstvenom nahođenju, Kompanija može. ali nije obavezna da pregleda i proveri bilo koje informacije koje joj dostavi klijent, u bilo koje svrhe. To je jasno navedeno, i svojim potpisomu nastavku klijent je takođe saglasan da Kompanija nema obavezu initi odgovornost prema klijentu uvezi sa gore navedenim pregledom ili proverom informacija.</p> <br />
                                <p className="uk-text-lead uk-text-muted uk-margin-remove-top">8. The Company will take measures to implement advanced data protection procedures and to update them from time to time for purpose of safeguarding Client's private information and Accounts.</p> <br />
                                <p className="uk-text-lead uk-text-muted uk-margin-remove-top">9. Upon registration with tradexmining.live, Client will be asked to choose a email and password to be used by Client for each future login and for the performance of Transactions and use of the Company's Services. In order to protect Clients' privacy and operation with tradexmining.live, sharing registration details (including without limitation, email and password) by Client with other persons or business entities is strictly prohibited. The Company shall not be held responsible for any damage or loss caused to Client due to improper use (including prohibited and unprotected use) or storage of such email and password, including any such use made by a third party, and whether or not known to or authorized by Client.</p> <br />
                                <p className="uk-text-lead uk-text-muted uk-margin-remove-top">10. Any use of tradexmining.live with the Client's email and password is Client's sole responsibility. The Company shall not be held responsible for any such use, including for validation that Client is actually operating his/her Account.</p> <br />
                                <p className="uk-text-lead uk-text-muted uk-margin-remove-top">11. Client is obliged to forthwith notify the Company's client service of any suspicion of unauthorized use of the Account.</p> <br />
                                <p className="uk-text-lead uk-text-muted uk-margin-remove-top">12. The Company does not store or collect any Credit Card data.</p> <br />
                                <p className="uk-text-lead uk-text-muted uk-margin-remove-top">12.1 In accordance with the recommendations of Payment Card Industry Security Standards Council, customer card details are protected using Transport Layer encryption — TLS 1.2 and application layer with algorithm AES and key length 256 bit.</p> <br />
                                <p className="uk-text-lead uk-text-muted uk-margin-remove-top">13. Cookies:</p> <br />
                                <p className="uk-text-lead uk-text-muted uk-margin-remove-top">Definition: A cookie is a small amount of data, which often includes a unique identifier, that is sent to your computer or mobile phone (referred to here as a «device») browser from a website's computer and is stored on your device's hard drive for tracking site usage. A website may send its own cookie to your browser if your browser's preferences allow it, but, to protect your privacy, your browser only permits a website to access the cookies it has already sent to you, not the cookies sent to you by other websites. Many websites do this whenever a user visits their website in order to track online traffic flows. A Client may choose to configure their browser to reject cookies by modifying their browser settings or preferences.</p> <br />
                                <p className="uk-text-lead uk-text-muted uk-margin-remove-top">Our cookies policy: During any visit to tradexmining.live website, the pages viewed, along with cookies, are downloaded to the Client's device. Cookies stored help determine the path the Client took on our site and they are used to anonymously identify recurring visits to the website and the most popular pages. However, the Company protects the Client's privacy by not storing the Client's names, personal details, emails, etc. Using cookies is an industry standard and is currently used by most major Websites. Stored cookies allow tradexmining.live website to be more user-friendly and efficient for Clients by allowing the Company to learn which information is more valued by Clients versus which isn't.</p> <br />
                                <p className="uk-text-lead uk-text-muted uk-margin-remove-top">14. The mobile app can gather anonymized stats on the installed applications.</p>        
                            </div>
                        </div>   
                    </div>
                </div>
            </div>
        </div>
    )
}